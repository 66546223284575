<template>
  <div class="grid">
    <div class="col-12">
      <h3>Статистика по курсу</h3>
      <div v-for="(question, index) in statistics" :key="index" class="question-card">
        <h3>[{{question.question_id}}] {{ question.question_text }}</h3>

        <!-- Выводим ответы для основного вопроса -->
        <ul>
          <li v-for="(stat, idx) in question.statistics" :key="idx">
            {{ stat.answer_text }}: <span style="color:blue"><strong>{{ stat.count }}</strong> ({{ stat.percentage }}%)</span>
          </li>
        </ul>

        <!-- Если есть подвопросы, выводим их -->
        <div v-if="question.subquestions.length > 0">
          <div v-for="(subquestion, idx) in question.subquestions" :key="idx">
            <h4>{{ subquestion.question_option }}</h4> <!-- Название подвопроса -->
            <ul>
              <li v-for="(substat, subidx) in subquestion.answers" :key="subidx">
                {{ substat.answer_text }}: <span style="color:blue"><strong>{{ substat.count }}</strong> ({{ substat.percentage }}%)</span>
              </li>
            </ul>
          </div>
        </div>
      </div>



    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { getCourseStatistics } from '../../services/apiService';

const statistics = ref([]);

// Получение статистики
const fetchStatistics = async () => {
  try {
    const response = await getCourseStatistics('PHARM');
    statistics.value = response.data;
  } catch (error) {
    console.error('Ошибка при получении статистики:', error);
  }
};

onMounted(() => {
  fetchStatistics();
});
</script>

<style scoped>
.question-card {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
}

h4 {
  margin-top: 20px;
  margin-bottom: 0;
  font-size:20px;
}

ul {
  margin: 0;
  padding-left: 20px;
}

li {
  margin: 5px 0;
}
</style>