import { createRouter, createWebHistory } from 'vue-router';
import AdminLayout from '../views/admin-editor/AdminLayout.vue';
import PublicLayout from '../views/public/PublicLayout.vue';
import Login from '../views/admin-editor/LoginPage.vue';

import SurveyList from '../views/admin-editor/SurveyList.vue';
import SurveyDetail from '../views/admin-editor/SurveyDetail.vue';
import ScreenDetail from '../views/admin-editor/ScreenDetail.vue';
import StatsPage from '../views/admin-editor/StatsPage.vue';
import PublicSurvey from '../views/public/PublicSurvey.vue';

import { getCookie } from '@/utils/cookies';

const adminRoutes = [
  { path: '/surveys', component: SurveyList },
  { path: '/stats', component: StatsPage },
  { path: '/surveys/:id', component: SurveyDetail },
  {
    path: '/survey/:surveyId/screen/:screenId',
    name: 'ScreenDetail',
    component: ScreenDetail,
    props: true
  }
];

const publicRoutes = [
  {
    path: '/',
    component: PublicSurvey,
    name: 'public-survey',
    props: route => ({
      courseId: route.query.courseId,
      moduleId: route.query.moduleId,
      studentId: route.query.studentId
    })
  }
];

const routes = [
  { path: '/login', component: Login },
  {
    path: '/admin',
    component: AdminLayout,
    children: [
      { path: '', component: SurveyList },
      ...adminRoutes
    ],
    meta: { requiresAuth: true }  // Устанавливаем мета-поле для проверки авторизации
  },
  {
    path: '/',
    component: PublicLayout,
    children: publicRoutes
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Проверяем авторизацию ТОЛЬКО для административных маршрутов
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = getCookie('admin_access');
    if (isAuthenticated) {
      next();
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
